'use strict';

/**
 * @ngdoc function
 * @name 360StoreAngularApp.controller:ReportsDynamicReportCtrl
 * @description
 * # ReportsDynamicReportCtrl
 * Controller of the 360StoreAngularApp
 */
'use strict';

angular.module('360StoreAngularApp')
	.controller('ReportsDynamicReportCtrl', function ($scope, $filter, api, $location) {
		$scope.reportName = $location.search().reportName;
		$scope.params = {};
		$scope.loading = true;
		$scope.results = null;

		const memoized = new Map();

		const memoize = (key, fn) => {
			if (!memoized.has(key)) {
				memoized.set(key, fn());
			}
			return memoized.get(key);
		};

		// Get report definition
		api.dynamicReportGet($scope.reportName).then(report => {
			$scope.report = report;
			$scope.loading = false;
		});

		// Product typeahead - returns promise of all products
		$scope.getProducts = (q) => memoize('products', () => api.getProducts())
			.then(arr => $filter('filter')(arr, q));

		// Product version typeahead
		$scope.getProductVersions = (productId,q) => {
			if (!productId) return Promise.resolve([]);
			return memoize(`versions-${productId}`, () => api.getProductVersions(productId))
				.then(arr => $filter('filter')(arr, q));
		};

		// Variant types typeahead
		$scope.getVariantTypes = (q) => memoize('variantTypes', () => api.adminVariantTypes())
			.then(arr => $filter('filter')(arr, q));

		$scope.runReport = async () => {
			try {
				$scope.loading = true;
				$scope.results = await api.dynamicReportPost($scope.reportName, $scope.params, 'JSON_OBJECT_ARRAY');
			} finally {
				$scope.loading = false;
			}
		};
	});
