'use strict';

/**
 * @ngdoc filter
 * @name 360StoreAngularApp.filter:titleCase
 * @function
 * @description
 * # titleCase
 * Filter in the 360StoreAngularApp.
 */
angular.module('360StoreAngularApp')
	.filter('titleCase', function () {

		function camelCaseToTitle(camelCase) {
			if (!camelCase) {
				return '';
			}

			const pascalCase = camelCase.charAt(0).toUpperCase() + camelCase.substr(1);
			return pascalCase
				.replace(/([a-z])([A-Z])/g, '$1 $2')
				.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
				.replace(/([a-z])(\d)/gi, '$1 $2')
				.replace('_', ' ')
				.replace(/(\d)([a-z])/gi, '$1 $2');
		}

		return function (input) {
			return camelCaseToTitle(input);
		};
	});
