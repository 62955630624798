'use strict';

/**
 * Global actions for the 360Store
 * @ngdoc function
 * @name 360StoreAngularApp.controller:360storeControllerCtrl
 * @description
 * # 360storeControllerCtrl
 * Controller of the 360StoreAngularApp
 */
angular.module('360StoreAngularApp')
	.controller('StoreCtrl', function ($rootScope, $uibModal, BUILD_DATE) {
		$rootScope.autoRenewTermsAndConditions = () => {
			$uibModal.open({
				templateUrl: 'views/modal-auto-renew-terms-and-conditions.html?v=' + BUILD_DATE,
			}).result.catch(() => {
			});
		};
	});
