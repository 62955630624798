'use strict';

/**
 * @ngdoc directive
 * @name 360StoreAngularApp.directive:helpTooltip
 * @description
 * # helpTooltip
 */
angular.module('360StoreAngularApp')
	.directive('helpTooltip', function () {
		return {
			restrict: 'E',
			scope: {
				text: '@tooltipText',
				placement: '@tooltipPlacement'
			},
			template:
				'<span class="fa fa-question-circle text-info"' +
				'      uib-tooltip="{{text}}"' +
				'      tooltip-class="tooltip-help"' +
				'      tooltip-placement="{{placement || \'right\'}}"></span>',
			replace: true
		};
	});
